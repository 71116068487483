import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/HomePage.css';
import logo from '../assets/logo.png'; // Make sure to add your logo file


function HomePage() {
  return (
    <div className="home-page">
      <header>
        <h1>Arcane Data LLC</h1>
        <nav>
          <a href="mailto:me@arcanedata.us" className="nav-button">Contact</a>
        </nav>
      </header>

      <main>
        <section className="hero" style={{'--logo-url': `url(${logo})`}}>
          <h2>Transforming data into insight, effortlessly.</h2>
          <p>Arcane Data helps businesses unlock the potential of their data through AI-driven automation and storytelling.</p>
        </section>

        <section className="features">
          <h2>What We Do</h2>
          <div className="feature-grid">
            <div className="feature-item">
              <h3>Technical Product Marketing</h3>
              <p>Craft compelling narratives that bridge your product and your customer.</p>
            </div>
            <div className="feature-item">
              <h3>AI-Driven Content Automation</h3>
              <p>Scale your marketing engine with our short-form content automation platform.</p>
            </div>
            <div className="feature-item">
              <h3>Fractional Leadership</h3>
              <p>Bring in seasoned expertise without the full-time commitment.</p>
            </div>
            <div className="feature-item">
              <h3>Identity & Security Expertise</h3>
              <p>Deep knowledge of SaaS, cybersecurity, and developer-first tools.</p>
            </div>
          </div>
        </section>

        <section className="cta">
          <h2>Let’s Build Something Arcane</h2>
          <a href="mailto:me@arcanedata.us" className="cta-button">Get In Touch</a>
        </section>
      </main>

      <footer>
        <p>&copy; {new Date().getFullYear()} Arcane Data LLC. All rights reserved.</p>
        <nav>
          <a href="mailto:me@arcanedata.us">Email Us</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms of Service</a>
        </nav>
      </footer>
    </div>
  );
}

export default HomePage;
