import React from 'react';

function Login() {
  const handleLogin = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    if (!apiUrl) {
      console.error('API URL not configured');
      return;
    }
    window.location.href = `${apiUrl}/auth/tiktok`;
  };

  return (
    <div>
      <h1>Welcome to Arcane Data</h1>
      <button onClick={handleLogin}>Login with TikTok</button>
    </div>
  );
}

export default Login;
