import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      
      <p>Last updated: 01.04.2025</p>

      <p>Arcane Data LLC ("we", "our", or "us") operates the Arcane Data website and related services (the "Service"). This page outlines our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

      <h2>Information Collection and Use</h2>

      <p>We collect several types of information to provide and improve our Service:</p>

      <ol>
        <li>
          <strong>Personal Data</strong>: While using our Service, we may ask you to provide certain personally identifiable information ("Personal Data") which may include, but is not limited to:
          <ul>
            <li>Email address</li>
            <li>First and last name</li>
            <li>Phone number</li>
            <li>Mailing address (including city, state, province, ZIP/postal code)</li>
          </ul>
        </li>
        <li>
          <strong>Usage Data</strong>: We may collect information on how the Service is accessed and used. This includes:
          <ul>
            <li>IP address</li>
            <li>Browser type and version</li>
            <li>Pages visited</li>
            <li>Time and date of access</li>
            <li>Time spent on pages</li>
            <li>Unique device identifiers</li>
            <li>Diagnostic data</li>
          </ul>
        </li>
      </ol>

      <h2>Use of Data</h2>

      <p>We use the collected data for the following purposes:</p>
      <ul>
        <li>To provide and maintain our Service</li>
        <li>To notify you about updates or changes to the Service</li>
        <li>To enable participation in interactive features when you choose to do so</li>
        <li>To provide customer support</li>
        <li>To analyze and improve the Service</li>
        <li>To monitor usage patterns</li>
        <li>To detect, prevent, and address technical issues</li>
      </ul>

      <h2>Transfer of Data</h2>

      <p>Your data, including Personal Data, may be transferred to — and maintained on — servers located outside your state, province, or country, where data protection laws may differ from those of your jurisdiction.</p>

      <h2>Disclosure of Data</h2>

      <p>We may disclose your Personal Data in good faith when necessary to:</p>
      <ul>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of Arcane Data LLC</li>
        <li>Prevent or investigate possible misconduct in connection with the Service</li>
        <li>Protect the personal safety of users or the public</li>
        <li>Protect against legal liability</li>
      </ul>
      </div>
  );
}

export default PrivacyPolicy;